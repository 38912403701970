import S3 from "aws-sdk/clients/s3";

const s3Client = new S3({
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
});

export async function uploadJsonToS3(bucketName, file, content) {
  const REGION = "eu-north-1";

  const params = {
    Bucket: bucketName,
    Key: file,
    Body: JSON.stringify(content),
    ContentType: "application/json",
  };

  try {
    const upload = await s3Client.putObject(params).promise();
  } catch (error) {
    throw error;
  }
}

export async function uploadFileOnS3(file, bucket) {
  const REGION = process.env.REACT_APP_REGION;

  const params = {
    Bucket: bucket,
    Key: file.name,
    Body: file,
  };
  try {
    const upload = await s3Client.putObject(params).promise();
    const encodedFileName = encodeURIComponent(file.name);
    const fileUrl = `https://${bucket}.s3.${REGION}.amazonaws.com/${encodedFileName}`;
    return fileUrl;
  } catch (error) {
    throw error;
  }
}

export async function fetchdatafroms3(bucketName, file) {
  const REGION = "eu-north-1";
  const params = {
    Bucket: bucketName,
    Key: file,
  };

  const s3Client = new S3({
    region: REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_ID,
    secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
  });

  try {
    const data = await s3Client.getObject(params).promise();
    const jsonData = JSON.parse(data.Body.toString("utf-8")); // Parse the JSON content
    return jsonData;
  } catch (error) {
    throw error;
  }
}
