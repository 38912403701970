import { Link } from "react-router-dom";

// function Navbar() {
//   return (
//     <nav className=" px-10 bg-cyan-700 flex text-white">
//       <div className="container items-center mx-auto flex gap-8 p-4">
//         <img src="/images/logo.png" className="w-40 mr-auto" />
//         <Link to="#">How it works</Link>
//         <Link to="#">Features</Link>
//         <Link to="#">Academy</Link>
//         <Link to="#">Service</Link>
//         <Link to="#">Pricing</Link>
//         <Link to="/register" className="bg-green-600 px-4 py-2 rounded-md">
//           Signup
//         </Link>
//         <Link to="/login">Login</Link>
//       </div>
//     </nav>
//   );
// }


function Navbar() {
  return (
    <nav className="bg-blue-500 text-white px-8 py-3">
      <div className="flex container mx-auto justify-between items-center">
        <div className="flex items-center">
          <h1 className="text-xl font-bold">sway gpt</h1>
          <ul className="ml-4 flex space-x-4">
            {/* <li><a href="#" className="hover:underline">How it Works</a></li>
            <li><a href="#" className="hover:underline">Whitelabel</a></li>
            <li><a href="#" className="hover:underline">Features</a></li>
            <li><a href="#" className="hover:underline">Landing Pages</a></li>
            <li><a href="#" className="hover:underline">Contact Us</a></li>
            <li><a href="#" className="hover:underline">Blog</a></li>
            <li><a href="#" className="hover:underline">Help Center</a></li> */}
          </ul>
        </div>
        <Link to="/" className="bg-white text-blue-500 px-4 py-2 rounded-md hover:bg-gray-100">Get in the Waitlist now</Link>
      </div>
    </nav>
  );
}

export default Navbar;
