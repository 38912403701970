import React, { useState } from "react";
import { setCookie } from "../../utils/cookie";
import { useNavigate } from "react-router-dom";
import { Eye, EyeClosed, Loader } from "lucide-react";
import { api } from "../../utils/api";
function FacebookLogin() {
  // State to store username, password, and error
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [show, setShown] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  async function handleLogin(e) {
    e.preventDefault();
    setLoading(true);
    try {
      const { access_token, expiresIn } = (
        await api.post("/login", { username, password })
      ).data;
      setCookie("token-swaygpt", access_token, expiresIn);
      navigate("/chat");
      setError("");
    } catch (err) {
      setError(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="h-screen max-w-md mx-auto px-6 flex flex-col justify-center items-center text-white">
      <form
        onSubmit={handleLogin}
        className="bg-neutral-800  p-6 shadow-md rounded-md w-full"
      >
        <h2 className="text-xl font-semibold mb-4">Login</h2>

        {/* Username input */}
        <div className="mb-4">
          <label className="block mb-2">Username</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            className="border border-gray-600 bg-gray-700 text-white px-3 py-2 w-full rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
            required
          />
        </div>

        {/* Password input */}
        <div className="mb-4 ">
          <label className="block mb-2">Password</label>
          <div className="flex gap-2 rounded-md items-center border-gray-600 bg-gray-700 text-white px-3 py-2 ">
            <input
              type={show ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="border bg-transparent outline-none border-none grow w-full rounded-md"
              required
            />
            <span className="" onClick={(e) => setShown(!show)}>
              {show ? <Eye /> : <EyeClosed />}
            </span>
          </div>
        </div>

        {/* Error message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Login button */}
        <button
          type="submit"
          className="bg-blue-600 hover:bg-blue-700 justify-center flex items-center gap-2 px-4 py-2 rounded-md text-white w-full transition duration-300"
        >
          {loading ? <Loader className="animate-spin" /> : null}
          Login
        </button>
      </form>
    </div>
  );
}

export default FacebookLogin;
