import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch blogs from an API
// export const fetchBlogs = createAsyncThunk("blogs/fetchBlogs", async () => {
//   const response = await axios.get("https://api.example.com/blogs");
//   return response.data;
// });

export const fetchBlogs = createAsyncThunk(
  "blogs/fetchBlogs",
  async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve([
          {
            id: 1,
            title: "The Impact of Piracy on the Video Game Industry",
            content: `
## The Impact of Piracy on the Video Game Industry

Video game piracy has been a significant issue for developers and publishers alike. While some argue that piracy harms the industry by reducing sales, others believe it can serve as free promotion for games.

### Understanding Piracy in Gaming

Piracy refers to the unauthorized copying and distribution of video games. It has been prevalent since the early days of gaming, especially with the rise of personal computers and the internet.

### Economic Effects

The economic effects of piracy are complex:

- **Lost Revenue**: Developers may lose potential sales due to pirated copies.
- **Increased Costs**: Companies may invest more in anti-piracy technologies and legal action.
- **Market Reach**: Some argue that piracy allows people to try games they otherwise couldn't afford, potentially leading to future sales.

### Counteracting Piracy

Developers are adopting various strategies to counteract piracy:

1. **Digital Rights Management (DRM)**: Many games include DRM to prevent unauthorized copying and distribution.
2. **Freemium Models**: Offering free-to-play games with optional purchases can attract players without the need for piracy.
3. **Engagement**: Developers are focusing on building communities around their games to encourage legitimate purchases.

### Conclusion

With digital distribution and anti-piracy technologies improving, the landscape is shifting. While the fight against piracy continues, developers are finding new ways to engage with their audience and provide value that piracy cannot replicate.

---

## The Future of Video Games: What’s Next?

The future of video games is incredibly exciting, with advancements in virtual reality (VR), augmented reality (AR), cloud gaming, and AI-driven experiences.

### Innovations on the Horizon

As technology continues to evolve, video games are becoming more immersive and accessible:

- **Virtual Reality (VR)**: VR allows players to experience games in a fully immersive environment.
- **Augmented Reality (AR)**: AR blends the real world with digital elements, creating unique gaming experiences.
- **Cloud Gaming**: Services like Google Stadia and Xbox Cloud Gaming enable players to stream games without the need for powerful hardware.

### The Role of AI

AI is transforming gaming in several ways:

1. **Dynamic Storytelling**: AI can adapt storylines based on player choices, creating a more personalized experience.
2. **Procedural Generation**: Games can create vast worlds with unique landscapes and quests using AI algorithms.

### Conclusion

Cross-platform gaming and metaverse-like environments are just the beginning. The gaming industry is poised for significant growth and innovation in the coming years.

---

## Video Games and Their Growing Popularity

Over the last few decades, video games have grown from a niche hobby to a mainstream form of entertainment.

### Demographic Shifts

Games now appeal to all age groups, genders, and demographics. The rise of mobile gaming has further expanded the audience:

- **Casual Gamers**: Mobile games attract a large number of casual players.
- **Esports**: Competitive gaming has gained popularity, with global tournaments drawing massive audiences.

### Cultural Impact

Video games have become a cultural phenomenon, influencing various aspects of society:

- **Media**: Games are now featured in movies, TV shows, and music.
- **Social Interaction**: Online multiplayer games foster social connections and communities.

### Conclusion

From casual mobile games to hardcore multiplayer experiences, video games continue to shape modern culture, with no signs of slowing down.

            `, // existing markdown content
            author: "John Doe",
            timestamp: "2024-10-01",
            tags: ["Piracy", "Gaming", "Industry"],
            authorImage: "https://via.placeholder.com/50", // Dummy image URL
          },
          {
            id: 2,
            title: "The Future of Video Games: What’s Next?",
            content: `
## The Impact of Piracy on the Video Game Industry

Video game piracy has been a significant issue for developers and publishers alike. While some argue that piracy harms the industry by reducing sales, others believe it can serve as free promotion for games.

### Understanding Piracy in Gaming

Piracy refers to the unauthorized copying and distribution of video games. It has been prevalent since the early days of gaming, especially with the rise of personal computers and the internet.

### Economic Effects

The economic effects of piracy are complex:

- **Lost Revenue**: Developers may lose potential sales due to pirated copies.
- **Increased Costs**: Companies may invest more in anti-piracy technologies and legal action.
- **Market Reach**: Some argue that piracy allows people to try games they otherwise couldn't afford, potentially leading to future sales.

### Counteracting Piracy

Developers are adopting various strategies to counteract piracy:

1. **Digital Rights Management (DRM)**: Many games include DRM to prevent unauthorized copying and distribution.
2. **Freemium Models**: Offering free-to-play games with optional purchases can attract players without the need for piracy.
3. **Engagement**: Developers are focusing on building communities around their games to encourage legitimate purchases.

### Conclusion

With digital distribution and anti-piracy technologies improving, the landscape is shifting. While the fight against piracy continues, developers are finding new ways to engage with their audience and provide value that piracy cannot replicate.

---

## The Future of Video Games: What’s Next?

The future of video games is incredibly exciting, with advancements in virtual reality (VR), augmented reality (AR), cloud gaming, and AI-driven experiences.

### Innovations on the Horizon

As technology continues to evolve, video games are becoming more immersive and accessible:

- **Virtual Reality (VR)**: VR allows players to experience games in a fully immersive environment.
- **Augmented Reality (AR)**: AR blends the real world with digital elements, creating unique gaming experiences.
- **Cloud Gaming**: Services like Google Stadia and Xbox Cloud Gaming enable players to stream games without the need for powerful hardware.

### The Role of AI

AI is transforming gaming in several ways:

1. **Dynamic Storytelling**: AI can adapt storylines based on player choices, creating a more personalized experience.
2. **Procedural Generation**: Games can create vast worlds with unique landscapes and quests using AI algorithms.

### Conclusion

Cross-platform gaming and metaverse-like environments are just the beginning. The gaming industry is poised for significant growth and innovation in the coming years.

---

## Video Games and Their Growing Popularity

Over the last few decades, video games have grown from a niche hobby to a mainstream form of entertainment.

### Demographic Shifts

Games now appeal to all age groups, genders, and demographics. The rise of mobile gaming has further expanded the audience:

- **Casual Gamers**: Mobile games attract a large number of casual players.
- **Esports**: Competitive gaming has gained popularity, with global tournaments drawing massive audiences.

### Cultural Impact

Video games have become a cultural phenomenon, influencing various aspects of society:

- **Media**: Games are now featured in movies, TV shows, and music.
- **Social Interaction**: Online multiplayer games foster social connections and communities.

### Conclusion

From casual mobile games to hardcore multiplayer experiences, video games continue to shape modern culture, with no signs of slowing down.

            `, // existing markdown content
            author: "Jane Smith",
            timestamp: "2024-10-05",
            tags: ["Future", "Technology", "Gaming"],
            authorImage: "https://via.placeholder.com/50", // Dummy image URL
          },
          {
            id: 3,
            title: "Video Games and Their Growing Popularity",
            content: `
## The Impact of Piracy on the Video Game Industry

Video game piracy has been a significant issue for developers and publishers alike. While some argue that piracy harms the industry by reducing sales, others believe it can serve as free promotion for games.

### Understanding Piracy in Gaming

Piracy refers to the unauthorized copying and distribution of video games. It has been prevalent since the early days of gaming, especially with the rise of personal computers and the internet.

### Economic Effects

The economic effects of piracy are complex:

- **Lost Revenue**: Developers may lose potential sales due to pirated copies.
- **Increased Costs**: Companies may invest more in anti-piracy technologies and legal action.
- **Market Reach**: Some argue that piracy allows people to try games they otherwise couldn't afford, potentially leading to future sales.

### Counteracting Piracy

Developers are adopting various strategies to counteract piracy:

1. **Digital Rights Management (DRM)**: Many games include DRM to prevent unauthorized copying and distribution.
2. **Freemium Models**: Offering free-to-play games with optional purchases can attract players without the need for piracy.
3. **Engagement**: Developers are focusing on building communities around their games to encourage legitimate purchases.

### Conclusion

With digital distribution and anti-piracy technologies improving, the landscape is shifting. While the fight against piracy continues, developers are finding new ways to engage with their audience and provide value that piracy cannot replicate.

---

## The Future of Video Games: What’s Next?

The future of video games is incredibly exciting, with advancements in virtual reality (VR), augmented reality (AR), cloud gaming, and AI-driven experiences.

### Innovations on the Horizon

As technology continues to evolve, video games are becoming more immersive and accessible:

- **Virtual Reality (VR)**: VR allows players to experience games in a fully immersive environment.
- **Augmented Reality (AR)**: AR blends the real world with digital elements, creating unique gaming experiences.
- **Cloud Gaming**: Services like Google Stadia and Xbox Cloud Gaming enable players to stream games without the need for powerful hardware.

### The Role of AI

AI is transforming gaming in several ways:

1. **Dynamic Storytelling**: AI can adapt storylines based on player choices, creating a more personalized experience.
2. **Procedural Generation**: Games can create vast worlds with unique landscapes and quests using AI algorithms.

### Conclusion

Cross-platform gaming and metaverse-like environments are just the beginning. The gaming industry is poised for significant growth and innovation in the coming years.

---

## Video Games and Their Growing Popularity

Over the last few decades, video games have grown from a niche hobby to a mainstream form of entertainment.

### Demographic Shifts

Games now appeal to all age groups, genders, and demographics. The rise of mobile gaming has further expanded the audience:

- **Casual Gamers**: Mobile games attract a large number of casual players.
- **Esports**: Competitive gaming has gained popularity, with global tournaments drawing massive audiences.

### Cultural Impact

Video games have become a cultural phenomenon, influencing various aspects of society:

- **Media**: Games are now featured in movies, TV shows, and music.
- **Social Interaction**: Online multiplayer games foster social connections and communities.

### Conclusion

From casual mobile games to hardcore multiplayer experiences, video games continue to shape modern culture, with no signs of slowing down.

`, // existing markdown content
            author: "Emily Johnson",
            timestamp: "2024-10-10",
            tags: ["Popularity", "Culture", "Gaming"],
            authorImage: "https://via.placeholder.com/50", // Dummy image URL
          },
        ]);
      }, 2000); // 2-second delay to simulate network latency
    });
  }
);

const blogSlice = createSlice({
  name: "blogs",
  initialState: {
    blogs: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBlogs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlogs.fulfilled, (state, action) => {
        state.loading = false;
        state.blogs = action.payload;
        state.error = null;
      })
      .addCase(fetchBlogs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default blogSlice.reducer;
