import React, { useRef, useState, useEffect, Fragment, useMemo } from "react";
import { Menu, Send } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import {
  addChat,
  clearChat,
  openPreviousConfirm,
  openPreviousModal,
  setLoading,
} from "../../redux/slice/chat/chat";
import rehypeReact from "rehype-react";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import { api } from "../../utils/api";
import { Trash } from "lucide-react";
import { getCookie } from "../../utils/cookie";
import TypingIndicator from "../../components/chat/typingAnimation";
function Chat() {
  const { chat } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const [message, setMessage] = useState();
  const chatContainerRef = useRef();
  const inputRef = useRef();
  const tokenCount = useRef(50);
  async function chatResponse(message) {
    if (!message) {
      return;
    }
    dispatch(setLoading());
    // clear after message is sent
    tokenCount.current = 50;
    setMessage("");
    inputRef.current.style.height = "auto";
    // add current user message
    dispatch(
      addChat({
        res: { role: "user", content: message },
        data: {
          role: "user",
          content: message,
        },
      })
    );
    // send message to backend
    let AIResponse;
    try {
      AIResponse = (
        await api.post("/chat", {
          prompt: message,
          status: 10,
          history: [...chat.slice(-process.env.REACT_APP_CHAT_HISTORY_LIMIT)],
          fb_access_token: process.env.REACT_APP_LOCAL_FB_TOKEN,
        })
      ).data;

      if (AIResponse?.status === 500) {
        throw new Error(AIResponse?.message);
      }
    } catch (err) {
      console.log(err);
      if (AIResponse?.status === 403) {
        dispatch(
          addChat({
            res: {
              role: "assistant",
              content: "Session expired, please login again",
            },
            data: {
              role: "assistant",
              content: "Session expired, please login again",
            },
          })
        );
        return;
      }

      AIResponse = {
        content: "Unable to answer you query for the moment",
      };
    }
    dispatch(
      addChat({
        res: {
          role: "assistant",
          content:
            AIResponse.content ||
            "there was some error on the error, plz try again",
        },
        data: AIResponse,
      })
    );

    dispatch(setLoading());
  }

  const handleChange = (e) => {
    const inputText = e.target.value;
    const currentTokenCount = tokenize(inputText).length;

    if (currentTokenCount <= 50) {
      setMessage(inputText);
      tokenCount.current = 50 - currentTokenCount;
    } else if (inputText.length < message.length) {
      setMessage(inputText);
      tokenCount.current = 50 - currentTokenCount;
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      const chatContainer = chatContainerRef.current;

      const isOverflowing =
        chatContainer.scrollHeight > chatContainer.clientHeight;

      if (isOverflowing) {
        chatContainer.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }
  }, [chat]);

  const tokenize = (text) => {
    return text.split(/\s+/).filter((token) => token.length > 0);
  };

  const lastInputFieldsIndex = useMemo(() => {
    let lastInputIndex = -1;
    let noFieldsCount = 0;

    for (let i = chat.length - 1; i >= 0; i--) {
      if (chat[i]?.input_fields) {
        lastInputIndex = i;
        noFieldsCount = 0;
        break;
      } else {
        if (noFieldsCount >= 3) break;
        noFieldsCount++;
      }
    }

    return lastInputIndex;
  }, [chat]);

  const lastInputFieldsIndex2 = useMemo(() => {
    let lastInputIndex = -1;
    let noFieldsCount = 0;

    for (let i = chat.length - 1; i >= 0; i--) {
      if (chat[i]?.status == 46) {
        lastInputIndex = i;
        noFieldsCount = 0;
        break;
      } else {
        if (noFieldsCount >= 3) break;
        noFieldsCount++;
      }
    }

    return lastInputIndex;
  }, [chat]);

  return (
    <div className="flex justify-center h-screen bg-neutral-900 text-white">
      <div className="sm:max-w-[80%] max-w-full h-full flex flex-col w-full p-4 relative">
        {chat.length > 0 ? null : (
          <Fragment>
            <div className="text-neutral-600 text-center mt-20 font-bold self-center">
              <p className="text-3xl">Welcome to</p>
              <p className=" font-bold pb-2 mb-6 text-5xl text-center gradient-text">
                SwayGPT
              </p>
              <p className="text-2xl">Your sales co-founder</p>
            </div>
          </Fragment>
        )}
        {chat.length > 0 && (
          <button
            onClick={() => dispatch(clearChat())}
            className="top-4 left-4 bg-neutral-800 text-neutral-500 rounded-[50px] py-2 px-4 flex items-center gap-2 w-fit"
          >
            <Trash size={18} />
            clear chat
          </button>
        )}
        <section
          ref={chatContainerRef}
          className="flex flex-col grow gap-8 overflow-y-auto custom-scrollbar mt-2 mb-4"
        >
          {chat.map((item, index) => {
            return (
              <div
                style={{
                  alignSelf: item?.role === "user" ? "end" : "start",
                }}
                key={index}
                className="flex flex-col gap-4 max-w-[80%]"
              >
                {item?.role === "assistant" ? (
                  <div className="flex gap-2 items-center ">
                    <img
                      src="/images/icon.png"
                      className="size-8 rounded-full"
                    />
                    <span>sway gpt AI</span>
                  </div>
                ) : null}
                <span
                  className={`${
                    item?.role === "user"
                      ? "bg-sky-600"
                      : "bg-neutral-800 unreset markDownContainer"
                  } text-white flex flex-col gap-2 border border-neutral-800 text-wrap overflow-x-auto  scroll-none base px-3 py-1 rounded-2xl`}
                >
                  <ReactMarkdown
                    rehypePlugins={[rehypeReact, remarkGfm, rehypeRaw]}
                  >
                    {item?.content}
                  </ReactMarkdown>
                  {item?.input_fields && index === lastInputFieldsIndex && (
                    <button
                      className="py-1 px-6 bg-sky-600 hover:bg-sky-700 rounded-md text-white font-semibold w-fit"
                      onClick={() => dispatch(openPreviousModal({ index }))}
                    >
                      Open
                    </button>
                  )}
                  {item?.status == 46 && index === lastInputFieldsIndex2 && (
                    <button
                      className="py-1 px-6 bg-sky-600 hover:bg-sky-700 rounded-md text-white font-semibold w-fit"
                      onClick={() => dispatch(openPreviousConfirm({ index }))}
                    >
                      Confirm
                    </button>
                  )}
                </span>
              </div>
            );
          })}
          <TypingIndicator />
        </section>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            inputRef.current.focus();
            chatResponse(message);
          }}
          className="flex mt-auto bg-neutral-800 items-end rounded-[25px] p-2 bottom-4 w-full right-0"
        >
          <div className="flex-1 max-h-[150px] overflow-y-auto px-2">
            <textarea
              value={message}
              ref={inputRef}
              onChange={(e) => {
                handleChange(e);
                const target = e.target;
                target.style.height = "auto";
                target.style.height = `${Math.min(
                  target.scrollHeight,
                  5 * 24
                )}px`;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  chatResponse(message);
                }
              }}
              className="text-white placeholder:text-neutral-400 outline-none bg-transparent text-lg rounded-lg resize-none overflow-y-auto w-full custom-scrollbar"
              placeholder="Your message here..."
              rows="1"
              style={{ maxHeight: "120px" }}
            />
          </div>
          <button
            disabled={!message}
            className="bg-sky-600 hover:bg-sky-700 outline-none disabled:bg-neutral-600 rounded-[50px] text-white h-10 w-10 flex justify-center items-center"
          >
            <Send size={18} />
          </button>
        </form>

        <div className="text-neutral-400 text-sm text-right">
          {tokenCount.current} words left
        </div>
      </div>
    </div>
  );
}

export default Chat;
