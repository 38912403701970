import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

// export const syncChat = createAsyncThunk("syncchat", async function (url) {
//   try {
//     const res = await axios.get(url);
//     return res.data;
//   } catch (err) {
//     throw new Error(err?.message);
//   }
// });

const ChatSlice = createSlice({
  name: "chat",
  initialState: {
    loading: false,
    chat: JSON.parse(localStorage.getItem("chat")) || [],
    data: JSON.parse(localStorage.getItem("data")) || [],
    index: null,
    status: 0,
    input_fields: [],
    function_name: null,
    confirmation_message: null,
    content: null,
    title: null,
    function_payload: null,
    // chatloading: true,
    // chaterror: null,
    // chatState: "chat",
  },

  reducers: {
    addChat: function (state, action) {
      if (action.payload?.data.type === "form") {
        const formattedString = Object.keys(action.payload.content)
          .map((key) => `${key}: ${action.payload.content[key]}`)
          .join("<br />");
        console.log(formattedString);
        state.chat = [
          ...state.chat,
          {
            role: "user",
            content: formattedString,
          },
        ];
        localStorage.setItem("chat", JSON.stringify(state.chat));
        return;
      }
      state.chat = [...state.chat, action.payload.res];
      localStorage.setItem("chat", JSON.stringify(state.chat));
      state.data = [...state.data, action.payload.data];
      localStorage.setItem("data", JSON.stringify(state.data));
      const data = action.payload.data;
      if (data?.status == 45) {
        state.status = data.status || 0;
        state.index = state.data.length - 1;
        state.function_name = data.function_name;
        state.function_name = data.function_name;
        state.input_fields = data.input_fields || [];
      }

      if (data?.status == 46) {
        state.status = data.status || 0;
        state.confirmation_message = data.confirmation_message;
        state.title = data.title;
        state.function_name = data.function_name;
        state.content = data.content;
        state.function_payload = data.function_payload;
      }
    },
    clearChat: function (state, action) {
      state.chat = [];
      state.data = [];
      localStorage.removeItem("chat");
      localStorage.removeItem("data");
      state.status = 0;
      state.index = null;
      state.input_fields = [];
    },
    closeModal: function (state, action) {
      state.status = 0;
    },
    openPreviousModal: function (state, action) {
      let data;
      for (let i = state.chat.length - 1; i >= 0; i--) {
        if (state.chat[i].input_fields) {
          data = state.chat[i];
          break;
        }
      }
      state.status = data.status;
      state.index = action.payload.index;
      state.input_fields = data.input_fields;
    },

    openPreviousConfirm: function (state, action) {
      const data = state.chat[action.payload.index];
      state.status = data.status || 0;
      state.confirmation_message = data.confirmation_message;
      state.title = data.title;
      state.function_name = data.function_name;
      state.content = data.content;
      state.function_payload = data.function_payload;
    },
    addFormResponse: function (state, action) {
      const data = state.chat[state.index];
      state.chat[state.index].content = `${data.content}\n${JSON.stringify(
        action.payload,
        null,
        2
      )}`;
    },
    setLoading: function (state, action) {
      state.loading = !state.loading;
    },
  },

  // extraReducers: (builder) => {
  //   builder.addCase(syncChat.pending, (state, action) => {
  //     state.chatloading = true;
  //     state.chaterror = null;
  //   });
  //   builder.addCase(syncChat.rejected, (state, action) => {
  //     state.chatloading = false;
  //     state.chaterror = action.error.message;
  //   });
  //   builder.addCase(syncChat.fulfilled, (state, action) => {
  //     state.chatloading = false;
  //     state.chaterror = null;
  //     state.chat = action.payload;
  //     console.log(action.payload);
  //   });
  // },
});

export const {
  addChat,
  clearChat,
  closeModal,
  openPreviousModal,
  setLoading,
  openPreviousConfirm,
} = ChatSlice.actions;
export default ChatSlice.reducer;
