import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addChat,
  addFormResponse,
  closeModal,
  setLoading,
} from "../../../redux/slice/chat/chat";
import { CircleX } from "lucide-react";
import { api } from "../../../utils/api";
import MultiSelect from "../multiSelect";
import { getCookie } from "../../../utils/cookie";
import { uploadFileOnS3 } from "../../../utils/aws";
function CreateCampaignModal() {
  const { chat, status, input_fields, function_name } = useSelector(
    (state) => state.chat
  );
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(() =>
    input_fields.reduce((acc, field) => {
      acc[field.key] = "";
      return acc;
    }, {})
  );

  if (status !== 45) return null;

  const handleChange = (label, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [label]: value,
    }));
  };

  const base64 = async (name, e) => {
    const file = e.target.files[0];
    if (file) {
      // get the url
      const url = await uploadFileOnS3(
        e.target.files[0],
        "static-content-for-text"
      );
      console.log(url);
      handleChange(name, url);
    }
    // if (file) {
    //   const res = await uploadImageToS3(file);
    //   console.log(res);
    //   handleChange(name, res);
    // }
    // if (file) {
    //   const reader = new FileReader();
    //   reader.onloadend = async () => {
    //     const base64String = reader.result;
    //     console.log("Base64 String:", base64String);
    //     const res = await uploadImageToS3("sample_image.png", base64String);
    //     handleChange(name, res);
    //   };
    //   reader.readAsDataURL(file);
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    dispatch(
      addChat(
        { res: { role: "user", content: formData } },
        { data: { role: "user", content: formData, type: "form" } }
      )
    );
    api
      .post("/chat", {
        prompt: JSON.stringify(formData),
        status: 10,
        history: [...chat.slice(-process.env.REACT_APP_CHAT_HISTORY_LIMIT)],
        fb_access_token: process.env.REACT_APP_LOCAL_FB_TOKEN,
      })
      .then((res) => {
        dispatch(
          addChat(
            addChat({
              res: {
                role: "assistant",
                content:
                  res.data.content ||
                  "there was some error on the error, plz try again",
              },
              data: res.data,
            })
          )
        );
      })
      .catch((err) => {
        console.log(err);
        dispatch(
          addChat({
            res: {
              role: "assistant",
              content: "Unable to answer you query for the moment",
            },
            data: {
              role: "assistant",
              content: "Unable to answer you query for the moment",
            },
          })
        );
      });
    dispatch(closeModal());
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={() => dispatch(closeModal())}
    >
      <div
        className="bg-[#2d2d2d] mx-4  flex flex-col gap-4 p-4 rounded-lg shadow-lg w-full max-w-sm  max-h-[500px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="text-center relative">
          <CircleX
            className="absolute top-0 right-0 cursor-pointer text-white"
            onClick={() => dispatch(closeModal())}
          />
          <p className="text-lg font-bold mb-3 text-white">
            Enter your details
          </p>
        </div>

        <form
          onSubmit={handleSubmit}
          className="p-6 space-y-4 bg-neutral-900 scroll-none py-4  max-h-full text-white rounded-md max-w-full mx-auto h-[75%] overflow-x-hidden overflow-y-auto"
        >
          {input_fields.map((field, index) => (
            <div key={index} className="flex flex-col space-y-1">
              <label className="font-semibold">{field.label}</label>

              {field.type === "text" && (
                <input
                  type="text"
                  placeholder={field.placeholder}
                  value={formData[field.key]}
                  onChange={(e) => handleChange(field.key, e.target.value)}
                  className="px-3 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
                />
              )}

              {field.type === "textarea" && (
                <textarea
                  type="text"
                  placeholder={field.placeholder}
                  value={formData[field.key]}
                  onChange={(e) => handleChange(field.key, e.target.value)}
                  className="px-3 py-2 min-h-[100px] bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
                />
              )}
              {field.type === "number" && (
                <input
                  type="text"
                  placeholder={field.placeholder}
                  value={formData[field.key]}
                  onChange={(e) => handleChange(field.key, e.target.value)}
                  className="px-3 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
                />
              )}
              {field.type === "option" && (
                <select
                  value={formData[field.key]}
                  onChange={(e) => handleChange(field.key, e.target.value)}
                  className="px-3 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
                >
                  <option value="">{field.placeholder}</option>
                  {field.options.map((option, optIndex) => (
                    <option key={optIndex} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              )}

              {field.type === "select" && (
                <MultiSelect
                  field={field}
                  formData={formData}
                  handleChange={handleChange}
                />
              )}

              {field.type === "image" && (
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => base64(field.key, e)}
                  className="px-3 py-2 bg-gray-800 border border-gray-700 rounded-md focus:outline-none focus:ring focus:ring-indigo-500"
                />
              )}
            </div>
          ))}
          <button
            type="submit"
            className="w-full py-2 bg-sky-600 hover:bg-sky-700 rounded-md text-white font-semibold"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default CreateCampaignModal;
