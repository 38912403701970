import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addChat,
  addFormResponse,
  closeModal,
  setLoading,
} from "../../../redux/slice/chat/chat";
import { CircleX } from "lucide-react";
import { api } from "../../../utils/api";
import { getCookie } from "../../../utils/cookie";
function CreateCampaignModal() {
  const {
    status,
    confirmation_message,
    content,
    title,
    chat,
    function_name,
    function_payload,
  } = useSelector((state) => state.chat);
  const dispatch = useDispatch();

  if (status !== 46) return null;

  const handleSubmit = async (e) => {
    dispatch(
      addChat({
        res: { role: "user", content: "Yes I want to confirm" },
        data: { role: "user", content: "Yes I want to confirm" },
      })
    );
    dispatch(closeModal());
    dispatch(setLoading());
    let AIResponse;
    try {
      AIResponse = (
        await api.post("/chat", {
          prompt: {
            function_name: function_name,
            args: function_payload,
            confirm: true,
          },
          status: 46,
          history: [...chat.slice(-process.env.REACT_APP_CHAT_HISTORY_LIMIT)],
          // history: chat,
          fb_access_token: process.env.REACT_APP_LOCAL_FB_TOKEN,
        })
      ).data;
    } catch (err) {
      console.log("error", err);
      AIResponse = {
        content: "Unable to answer you query for the moment",
      };
    }
    dispatch(setLoading());
    dispatch(
      addChat({
        res: {
          role: "assistant",
          content:
            AIResponse.content ||
            "there was some error on the error, plz try again",
        },
        data: AIResponse,
      })
    );
  };

  const handleCancel = async () => {
    dispatch(
      addChat({
        res: {
          role: "user",
          content: "No I do not want to confirm",
        },
        data: {
          role: "user",
          content: "No I do not want to confirm",
        },
      })
    );
    dispatch(closeModal());
    dispatch(setLoading());

    let AIResponse;
    try {
      AIResponse = (
        await api.post("/chat", {
          prompt: {
            function_name: function_name,
            args: function_payload,
            confirm: false,
          },
          status: 46,
          history: [...chat.slice(-process.env.REACT_APP_CHAT_HISTORY_LIMIT)],
          fb_access_token: process.env.REACT_APP_LOCAL_FB_TOKEN,
        })
      ).data;
    } catch (err) {
      console.log("error", err);
      AIResponse = {
        content: "Unable to answer you query for the moment",
      };
    }
    dispatch(setLoading());
    dispatch(
      addChat({
        res: {
          role: "assistant",
          content:
            AIResponse.content ||
            "there was some error on the error, plz try again",
        },
        data: AIResponse,
      })
    );
  };

  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
      onClick={() => dispatch(closeModal())}
    >
      <div
        className="bg-[#2d2d2d] p-4 rounded-lg shadow-lg w-full max-w-sm relative"
        onClick={(e) => e.stopPropagation()}
      >
        <CircleX
          className="absolute top-2 right-2 cursor-pointer text-white"
          onClick={() => dispatch(closeModal())}
        />
        <div className="text-center">
          <p className="text-lg font-bold mb-3 text-white">{title}</p>
        </div>
        <div className="p-6 space-y-4 bg-neutral-900 text-white rounded-md max-w-md mx-auto">
          <div className="text-center">{confirmation_message}</div>
          <div className="flex justify-center">
            <button
              className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              onClick={handleCancel}
            >
              Cancel
            </button>
            <button
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded ml-2"
              onClick={handleSubmit}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateCampaignModal;
