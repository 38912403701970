import React, { Fragment } from "react";
import { getCookie } from "../../utils/cookie";
import { Navigate, Outlet } from "react-router-dom";
function ProtectedRoute() {
  const token = getCookie("token-swaygpt");
  if (!token) {
    return <Navigate to="/login" />;
  }
  return <Outlet />;
}

export default ProtectedRoute;
