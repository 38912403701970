import React, { useState, useEffect, useRef } from "react";

function MultiSelect({ field, formData, handleChange }) {
  const [selectedOptions, setSelectedOptions] = useState(
    formData[field.key] || []
  );
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleOption = (option) => {
    const updatedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];
    setSelectedOptions(updatedOptions);
    handleChange(field.key, updatedOptions);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="px-3 py-2 bg-gray-800 border border-gray-700 rounded-md w-full text-left focus:outline-none"
        type="button"
      >
        {selectedOptions.length > 0
          ? selectedOptions.join(", ")
          : field.placeholder}
      </button>

      {isDropdownOpen && (
        <div className="absolute z-10 mt-2 bg-gray-800 border border-gray-700 rounded-md max-h-60 overflow-auto w-full">
          {field.options.map((option, index) => (
            <div
              key={index}
              onClick={() => toggleOption(option.value)}
              className="px-3 py-2 cursor-pointer hover:bg-gray-700 flex items-center"
            >
              <input
                type="checkbox"
                checked={selectedOptions.includes(option.value)}
                readOnly
                className="mr-2"
              />
              <span>{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default MultiSelect;
