import { configureStore } from "@reduxjs/toolkit";
import BlogsSlice from "./slice/blogs/blogslist";
import ChatSlice from "./slice/chat/chat";
const store = configureStore({
  reducer: {
    blogs: BlogsSlice,
    chat: ChatSlice
  },
});

export default store;
