import React, { Fragment, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  WaitList,
  Blogs,
  BlogDetail,
  Terms,
  FacebookLogin,
  Chat,
  Login,
} from "./pages/index";

import { ProtectedRoute } from "./components";
import Modal from "./components/chat/Modal";
import { fetchBlogs } from "./redux/slice/blogs/blogslist";
import { setCookie } from "./utils/cookie";
import { useNavigate } from "react-router-dom";
function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   dispatch(fetchBlogs());
  //   if (process.env.NODE_ENV === "development") {
  //     setCookie("token-swaygpt", process.env.REACT_APP_LOCAL_TOKEN, "1d");
  //     navigate("/chat");
  //   }
  // }, []);
  return (
    <Fragment>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path={"/chat"} element={<Chat />} />
        </Route>
        <Route path={"/"} element={<WaitList />} />
        <Route path={"/login"} element={<Login />} />
        <Route path={"/flogin"} element={<FacebookLogin />} />
        <Route path={"/blogs"} element={<Blogs />} />
        <Route path={"/blogs/:id"} element={<BlogDetail />} />
        <Route path={"/terms-and-privacy"} element={<Terms />} />
      </Routes>
      <Modal />
    </Fragment>
  );
}

export default App;
