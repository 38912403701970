import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

function BlogDetail() {
  const { id } = useParams(); // Get the blog ID from the URL
  const dispatch = useDispatch();
  const { blogs, loading, error } = useSelector((state) => state.blogs);


  // Find the blog post by ID
  const blog = blogs.find((b) => b.id === parseInt(id, 10));

  if (loading) {
    return <div className="text-center text-lg text-gray-500">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-lg text-red-500">Error: {error}</div>;
  }

  if (!blog) {
    return <div className="text-center text-lg text-gray-500">Blog not found</div>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold mb-4">{blog.title}</h1>
      <ReactMarkdown className="prose">{blog.content}</ReactMarkdown>
    </div>
  );
}

export default BlogDetail;
