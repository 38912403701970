import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Navbar } from "../../components";
import ReactMarkdown from "react-markdown"; // Import react-markdown

// Skeleton component for loading state
const SkeletonBlog = () => (
  <div className="bg-gray-200 animate-pulse rounded-lg p-6 shadow-md">
    <div className="h-6 bg-gray-300 rounded mb-4"></div>
    <div className="h-4 bg-gray-300 rounded mb-2"></div>
    <div className="h-4 bg-gray-300 rounded mb-2"></div>
    <div className="flex justify-between items-center text-sm text-gray-500 mb-4">
      <span className="h-4 bg-gray-300 rounded w-1/3"></span>
      <span className="h-4 bg-gray-300 rounded w-1/5"></span>
    </div>
    <div className="h-4 bg-gray-300 rounded mb-2"></div>
    <div className="h-4 bg-gray-300 rounded mb-2"></div>
  </div>
);

function Blogs() {
  const { blogs, loading, error } = useSelector((state) => state.blogs);

  if (loading) {
    return (
      <Fragment>
        <Navbar />
        <section className="px-10 flex flex-col">
          <h1 className="container mx-auto text-4xl font-bold mt-16 mb-4">
            Welcome to Our Blogs
          </h1>
          <p className="container mx-auto text-lg mb-8">
            Explore a variety of topics and insights through our curated blog
            posts. Dive into articles that inspire, educate, and entertain!
          </p>
          <div className="container mx-auto flex flex-col space-y-6">
            {/* Render multiple skeletons for loading state */}
            {[...Array(3)].map((_, index) => (
              <SkeletonBlog key={index} />
            ))}
          </div>
        </section>
      </Fragment>
    );
  }

  if (error) {
    return (
      <div className="text-center text-lg text-red-500">Error: {error}</div>
    );
  }

  return (
    <Fragment>
      <Navbar />
      <section className="px-10 flex flex-col">
        <h1 className="container mx-auto text-4xl font-bold mt-16 mb-4">
          Welcome to Our Blogs
        </h1>
        <p className="container mx-auto text-lg mb-8">
          Explore a variety of topics and insights through our curated blog
          posts. Dive into articles that inspire, educate, and entertain!
        </p>
        <div className="container mx-auto flex flex-col space-y-6">
          {blogs.map((blog) => (
            <div
              key={blog.id}
              className="bg-white border-cyan-600 border-l-[4px] shadow-md rounded-lg p-6 transition-transform duration-200 hover:shadow-lg "
            >
              <Link to={`/blogs/${blog.id}`} className="no-underline">
                <h2 className="text-2xl font-semibold text-cyan-600 hover:text-cyan-800">
                  {blog.title}
                </h2>
                {/* Use ReactMarkdown to render blog content */}
                
                <div className="flex justify-between items-center text-sm text-gray-500 mb-4">
                  <span>
                    By {blog.author} on{" "}
                    {new Date(blog.timestamp).toLocaleDateString()}
                  </span>
                  <span className="bg-blue-100 text-cyan-700 rounded-full px-2 py-1 text-xs">
                    {blog.tags.join(", ")}
                  </span>
                </div>

                <ReactMarkdown className="prose">{blog.content.split("\n\n").slice(0,2).join("\n\n")}</ReactMarkdown>
                <button className="mt-2 bg-cyan-600 text-white rounded-lg px-4 py-2 hover:bg-cyan-700 transition-colors duration-200">
                  Read More
                </button>
              </Link>
            </div>
          ))}
        </div>
      </section>
    </Fragment>
  );
}

export default Blogs;
