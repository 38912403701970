import React from "react";
import ComfirmModal from "./comfirmModal";
import CreateCampaignModal from "./createCampaignModal";
import { useSelector } from "react-redux";
function Index() {
  const { status } = useSelector((state) => state.chat);

  const renderModal = () => {
    switch (status) {
      case 45:
        return <CreateCampaignModal />;
      case 46:
        return <ComfirmModal />;
      default:
        return null;
    }
  };

  return renderModal();
}

export default Index;
