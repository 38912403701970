import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { setCookie, getCookie } from "../../utils/cookie";

const loadFacebookSDK = () => {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: process.env.REACT_APP_APP_ID,
      cookie: true,
      xfbml: true,
      version: "v21.0",
    });
  };
  (function (d, s, id) {
    var js,
      fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  })(document, "script", "facebook-jssdk");
};

const FacebookBusinessLogin = () => {
  const navigate = useNavigate();
  useEffect(() => {
    loadFacebookSDK();
  }, []);

  const handleBusinessLogin = () => {
    window.FB.login(
      (response) => {
        if (response.authResponse) {
          const accessToken = response.authResponse.accessToken;
          // console.log("Access Token:", accessToken);
          setCookie("token-swaygpt", accessToken, 4 * 60 * 60 * 1000);
          navigate("/chat");
          // window.FB.api("/me", { fields: "name,email" }, (userInfo) => {
          //   console.log("User Info:", userInfo);
          // });

          // Fetch business data with the access token
          // window.FB.api(
          //   "/me/businesses",
          //   "GET",
          //   { access_token: accessToken },
          //   (businessData) => {
          //     console.log("Business Data:", businessData);
          //   }
          // );
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        scope:
          "ads_management,ads_read,pages_show_list,email,pages_read_engagement,pages_manage_metadata",
      }
    );
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <button
        onClick={handleBusinessLogin}
        className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-4 rounded-full"
      >
        Login with Facebook Business
      </button>
    </div>
  );
};

export default FacebookBusinessLogin;
