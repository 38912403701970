import axios from "axios";
import { getCookie } from "./cookie";
const instance = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:6436"
      : //"https://3dzmidvvh5.execute-api.us-east-2.amazonaws.com"
        "https://3dzmidvvh5.execute-api.us-east-2.amazonaws.com",
  headers: {
    // Authorization: `Bearer ${getCookie("token-swaygpt")}`,
    Authorization: "Bearer " + process.env.REACT_APP_LOCAL_TOKEN,
  },
});

export const api = instance;
