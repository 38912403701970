import { Fragment } from "react";
import { Navbar } from "../../components";

function Terms() {
  return (
    <Fragment>
      {/* <Navbar /> */}
      <div className="container  bg-neutral-900 text-white p-8 flex flex-col gap-4 mx-auto">
        {/* terms and contdition header */}
        <header id="terms" className="text-3xl font-bold text mb-6">
          Terms and Conditions
        </header>

        {/* Terms Section */}
        <section className="space-y-4">
          <Section
            title="1. Introduction"
            content="Welcome to SwayGPT, an AI-powered chatbot designed to assist you in managing your ad campaigns across multiple platforms, including Facebook (Meta), Google, and other advertising networks. SwayGPT enables you to retrieve data, create and optimize ad campaigns, and interact with your ad management platforms through a chat interface. By using SwayGPT, you agree to comply with and be bound by the following terms and conditions."
          />
          <Section
            title="2. Acceptance of Terms"
            content="By accessing or using SwayGPT, you agree to these Terms and Conditions in full. If you do not accept these Terms, you must immediately discontinue use of the service. These Terms may be updated from time to time without notice."
          />
          <Section
            title="3. Services Provided"
            content={
              <Fragment>
                <span>SwayGPT provides the following services:</span>
                <ul className="list-disc pl-5">
                  <li>
                    Campaign Creation: SwayGPT can create ad campaigns on your
                    behalf using information provided via chat
                  </li>
                  <li>
                    Data Retrieval: SwayGPT can retrieve data related to your ad
                    performance across various platforms, including{" "}
                    <a
                      href="https://www.facebook.com/"
                      target="_blank"
                      className="font-bold text-blue-500"
                    >
                      Facebook
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.google.com/"
                      target="_blank"
                      className="font-bold text-blue-500"
                    >
                      Google
                    </a>
                    .
                  </li>
                  <li>
                    Optimization Suggestions: SwayGPT may provide optimization
                    suggestions based on its analysis of your campaign data
                  </li>
                </ul>
              </Fragment>
            }
          />
          <Section
            title="4. User Responsibilities"
            content={
              <Fragment>
                <span>You are responsible for:</span>
                <ul className="list-disc pl-5">
                  <li>
                    Ensuring that the campaigns created by SwayGPT comply with
                    the respective platform’s ad policies
                  </li>
                  <li>
                    Providing accurate and up-to-date information to SwayGPT for
                    the creation of ad campaigns
                  </li>
                  <li>
                    Reviewing and approving any campaigns created by SwayGPT
                    before they are published
                  </li>
                  <li>
                    Ensuring that your ad platform account credentials are
                    secure.
                  </li>
                </ul>
              </Fragment>
            }
          />
          <Section
            title="5. Limitation of Liability"
            content="SwayGPT and the parent company do not hold any responsibility for any gains or losses in the money spent on advertising or any outcomes from the use of SwayGPT. SwayGPT is merely an assistive tool, and all decisions or actions made through SwayGPT or by SwayGPT are the responsibility of the user and the business connected to SwayGPT. While we aim to provide accurate and helpful suggestions, we cannot guarantee outcomes from campaigns managed through SwayGPT."
          />
          <Section
            title="6. Disclaimer of Warranties"
            content="SwayGPT is provided 'as is,' and we make no representations or warranties, express or implied, regarding its functionality, performance, or accuracy. We are not liable for any direct, indirect, incidental, or consequential damages arising from your use of SwayGPT."
          />
          <Section
            title="7. Termination"
            content="We reserve the right to terminate or suspend access to SwayGPT at any time, with or without cause or notice. Upon termination, your right to use SwayGPT will cease immediately."
          />
          <Section
            title="8. Governing Law"
            content="These Terms and Conditions are governed by and construed in accordance with the laws of India and USA. Any disputes relating to these Terms will be subject to the exclusive jurisdiction of the courts of India and USA.
"
          />
        </section>

        {/* privacy policy header */}
        <header id="privacy" className="text-3xl mt-28 font-bold text mb-6">
          Privacy Policy
        </header>

        {/* privacy policy section */}
        <section className="space-y-4 ">
          <Section
            title="1. Introduction"
            content="This Privacy Policy explains how SwayGPT ('we,' 'our,' or 'us') collects, uses, discloses, and protects your personal information when you use our AI chatbot service to manage ad campaigns on platforms such as Facebook (Meta), Google, and others. By using SwayGPT, you consent to the data practices described in this Privacy Policy."
          />
          <Section
            title="2. Information We Collect"
            content={
              <ul className="list-disc pl-5">
                <li>
                  We collect the following types of information when you
                  interact with SwayGPT: Personal Information: This includes
                  your name, email address, account credentials, and other
                  information you provide directly when using SwayGPT
                </li>
                <li>
                  Usage Data: We collect information about how you use SwayGPT,
                  including ad creation data, performance insights, and chatbot
                  interaction data
                </li>
                <li>
                  Technical Data: We collect information such as your IP
                  address, browser type, device information, and operating
                  system.
                </li>
              </ul>
            }
          />
          <Section
            title="3. How We Use Your Information"
            content={
              <ul className="list-disc pl-5">
                <li>
                  We use your information to: Provide and manage the SwayGPT
                  services, including ad campaign creation and data retrieval
                </li>
                <li>
                  Improve our services based on user interaction and feedback
                </li>
                <li>Communicate with you about updates and support</li>
                <li>Ensure compliance and security.</li>
              </ul>
            }
          />
          <Section
            title="4. How We Share Your Information"
            content={
              <ul className="list-disc pl-5">
                <li>
                  Platforms such as Facebook and Google to manage your ad
                  campaigns.
                </li>
                <li>
                  Third-party service providers for technical support and
                  service enhancements.
                </li>
                <li>Legal entities, if required by law or regulation.</li>
              </ul>
            }
          />
          <Section
            title="5. Data Retention and Security"
            content="We retain your personal information only for as long as necessary to provide our services and as required by law. We implement industry-standard security measures, but no system is completely secure."
          />
          <Section
            title="6. Disclaimer of Responsibility for Outcomes"
            content="Although we carefully maintain user data, SwayGPT and the parent company are not responsible for any financial losses or gains resulting from the use of the platform or any advice made. All decisions and actions made through SwayGPT or by SwayGPT are the user’s responsibility, and SwayGPT does not hold any liability for outcomes on the user’s ad platforms."
          />
          <Section
            title="7. Changes to the Privacy Policy"
            content="We may update this Privacy Policy from time to time. Users are encouraged to review it periodically to stay informed of any changes."
          />
          <Section
            title="8. Contact us"
            content={
              <p>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at{" "}
                <a className="text-blue-500 underline" href="mailtp:team@swaygpt.com">team@swaygpt.com</a>.
              </p>
            }
          />
        </section>

        {/* Footer Section */}
        <footer className="text-sm text-gray-500 text-center mt-6">
          
        </footer>

        {/* Action Buttons */}
      </div>
    </Fragment>
  );
}

const Section = ({ title, content }) => {
  return (
    <div>
      <h2 className="text-xl font-semibold mb-2">{title}</h2>
      <p className="text-neutral-300 font-cambria  leading-relaxed">{content}</p>
    </div>
  );
};

export default Terms;
