import { useState, useEffect } from "react";
import { fetchdatafroms3, uploadJsonToS3 } from "../../utils/aws";

function Waitlist() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    setLoading(true);

    try {
      // Fetch existing waitlist data from S3
      let data =
        (await fetchdatafroms3(
          "himank-funny-image-upload",
          "swayGptWaitlist.json"
        )) || [];

      // Check if email is already in the list
      if (data.includes(email)) {
        setLoading(false);
        setSuccess(true);
        return;
      }

      // Add new email to the list
      data.push(email);

      // Upload updated data back to S3
      await uploadJsonToS3(
        "himank-funny-image-upload",
        "swayGptWaitlist.json",
        data
      );
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
    setSuccess(true);
  };

  return (
    <div className="bg-neutral-900 h-screen flex items-center justify-center">
      <div className="bg-neutral-800 text-white mx-4 text-center  p-10 rounded-2xl shadow-lg max-w-md">
        <p className="text-lg font-medium mb-2">Welcome to</p>
        <div className="text-4xl font-bold mb-6 gradient-text">SwayGPT</div>
        <p className="text-neutral-400 mb-4">
          Join our waitlist to be the first to know when we launch!
        </p>
        
        {/* Email Input Field */}
        <form onSubmit={onSubmit}>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="outline-none bg-neutral-700 text-neutral-300 placeholder-inherit p-3 rounded-full w-full mb-4 transition"
            required
          />
          <button
            type="submit"
            className="bg-gradient-to-r from-cyan-500 to-blue-500 text-white p-3 rounded-full w-full hover:from-cyan-400 hover:to-blue-400 transition"
            disabled={loading}
          >
            {loading ? (
              <span className="flex items-center justify-center">
                <svg
                  className="animate-spin h-5 w-5 mr-3"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" strokeWidth="4" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 0114.32-5.21A6.003 6.003 0 0112 4a6.003 6.003 0 00-5.32 3.79A8 8 0 014 12z" />
                </svg>
                Loading...
              </span>
            ) : (
              "Join Waitlist"
            )}
          </button>
        </form>

        {/* Success Message */}
        {success && (
          <p className="mt-4 text-green-600">
            You have successfully joined the waitlist!
          </p>
        )}
      </div>
    </div>
  );
}

export default Waitlist;
