import React from "react";
import { useSelector } from "react-redux";
const TypingIndicator = () => {
  const { loading } = useSelector((state) => state.chat);

  const typingIndicatorStyle = {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    marginLeft: "10px",
  };

  const dotStyle = {
    width: "8px",
    height: "8px",
    backgroundColor: "#898888",
    borderRadius: "50%",
    animation: "typing 0.8s infinite ease-in-out",
  };

  const typingKeyframes = `
    @keyframes typing {
      0%, 100% {
        transform: scale(0.8);
        opacity: 0.6;
      }
      50% {
        transform: scale(1);
        opacity: 1;
      }
    }
  `;

  const styleSheet = document.styleSheets[0];
  styleSheet.insertRule(typingKeyframes, styleSheet.cssRules.length);

  return (
    loading && (
      <div style={typingIndicatorStyle}>
        <div style={{ ...dotStyle, animationDelay: "0s" }}></div>
        <div style={{ ...dotStyle, animationDelay: "0.2s" }}></div>
        <div style={{ ...dotStyle, animationDelay: "0.4s" }}></div>
      </div>
    )
  );
};

export default TypingIndicator;
